import moment from 'moment'

export const dateend = (date: Date) => {
    const hour = date.getHours() % 12 || 12
    const minutes = date.getMinutes().toString()
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM'
    return `${hour.toString().padStart(2, '0')}:${minutes.length === 1 ? minutes.padStart(2, '0') : minutes} ${ampm}`
}

export const formatDate = (listDateString: string) => {
    return moment(listDateString).format('YYYY/MM/DD hh:mm A')
}

export const formatDateTimeWithAMPM = (dateString: string) => {
    if (!dateString) { return null }

    const date = new Date(dateString)

    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone: 'UTC'
    }).format(date)
}

export const formatTimeForOpenHouse = (dateString: string) => {
    if (!dateString) { return null }

    const date = new Date(dateString)
    return new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone: 'UTC'
    }).format(date)
}

export const formatOpenHouseDate = (startDate: string, endDateString: string) => {
    const startDateAndTime = formatDateTimeWithAMPM(startDate)
    const endDate = new Date(endDateString)
    const endTime = formatTimeForOpenHouse(endDate)
    return `${startDateAndTime} -> ${endTime}`
}

export const formatTimeWithAMPM = (time) => {
    if (!time) { return null }
    const [hour, minutes] = time.split(':').map(Number)
    const period = hour >= 12 ? 'PM' : 'AM'
    const formattedHour = hour % 12 || 12
    return `${formattedHour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`
}
